
body, 
.App {
  margin: 0;
  background-color: #fff;
}

html, body{
  width: 100%;
  height: 100%;
}